import { Translation } from "~/common/Translation"
import { Card, CardContent } from "~/ui/card"
import { H3, P } from "~/ui/typography"
import { useApplicationLayout } from "../layouts/ApplicationLayout"
import { useEffect } from "react"
import { Button } from "~/ui/button"

export const ApplicationLandingScreen = () => {
  const { setAction } = useApplicationLayout()

  useEffect(() => {
    setAction(<Button className="bg-highlight">Action</Button>)

    return () => {
      setAction(null)
    }
  }, [setAction])

  return (
    <div className="flex flex-col items-center gap-8">
      <H3>
        <Translation ns="application" i18nKey={"landing.title"} />
      </H3>
      <P>
        <Translation ns="application" i18nKey={"landing.subtitle"} />
      </P>
      <Card className="pt-4">
        <CardContent>Hello</CardContent>
      </Card>
    </div>
  )
}
