import { Route } from "react-router-dom"
import { applicationLandingPath } from "~/common/paths"
import { ApplicationLayout } from "./layouts/ApplicationLayout"
import { ApplicationLandingScreen } from "./screens/ApplicationLandingScreen"

export const useApplicationRoutes = () => {
  return (
    <Route element={<ApplicationLayout />}>
      <Route
        path={applicationLandingPath.pattern}
        element={<ApplicationLandingScreen />}
      />
    </Route>
  )
}
